import React,{Fragment} from 'react'
import { Link } from 'react-router-dom'

const ServiceContent = [
    {
        icon: "icon_31",
        title: 'Análisis de datos',
        desc: `Examinamos los datos para extraer información clave y enfocados en limpieza, transformación y visualización.`,
        arrow: 'icon_20',
        datadelay: '',
        dataAos: 'fade-right',
        className: ''
    }, {
        icon: "icon_32",
        title: 'Ciencia de datos',
        desc: `Capturamos y procesamos la información, ofreciendo perspectivas clave para decisiones estratégicas empresariales.`,
        arrow: 'icon_20',
        datadelay: '100',
        dataAos: 'fade-up',
        className: 'active'
    }, {
        icon: "icon_33",
        title: 'Análisis descriptivo',
        desc: `Encontramos patrones y tendencias en datos mediante estadísticas simples y gráficos claros.`,
        arrow: 'icon_20',
        dataDelay: '',
        dataAos: 'fade-left',
        className: ''
    }, 
]
const FancyFeatureSeventeen = () => {
    return ( 
    <Fragment> 
      <div className="row justify-content-center pt-30">
          {ServiceContent.map((val, i) => (
              <div key={i} className="col-lg-4 col-md-6" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
                  <div
                      className={`block-style-twelve block-space mt-30 ${val.className}`}>
                      <div className="icon d-flex align-items-end "><img src={`images/icon/${val.icon}.svg`} alt=""/></div>
                      <div className='mt-40'></div>
                      <h5 className=''>
                          {val.title}
                      </h5>
                      <p>{val.desc}</p>
                      
                  </div>
                  {/* /.block-style-twelve */}
              </div>
          ))}

      </div> 
    </Fragment>
  )
}

export default FancyFeatureSeventeen