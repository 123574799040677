import React, {Fragment} from 'react'

const ProcessContent = [
    {
        num: 1,
        className: 'numb tran3s',
        heading: 'Comprensión del escenario actual',
        desc: 'Conocemos sus equipos, sistemas, infraestructuras, procesos, capacidades, herramientas y datos disponibles actuales.   Análisis de objetivos comerciales y tecnológicos, así como puntos débiles actuales.',
        dataDelay: ''
    },  {
        num: 2,
        className: 'numb tran3s',
        heading: 'Captura de datos',
        desc: 'Construimos una base sólida con sistemas de captura de datos modernos para aprovechar las mejores prácticas de ingeniería de datos.',
        dataDelay: '100'
    }, {
        num: 3,
        className: 'numb tran3s',
        heading: 'Optimización',
        desc: 'Monitoreamos y evolucionamos sus sistemas de datos con mejoras. ',
        dataDelay: '100'
    },
]

const FancyFeatureNineteen = () => {
    return (
        <Fragment>
            <div className="block-style-thirteen" data-aos="fade-right">
                <div className="title-style-three pb-15">
                    <h2 className="main-title">¿Cómo funciona <span>Datafy</span>?</h2>
                </div>
                {/* /.title-style-three */}
                <ul className="style-none list-item">
                    {ProcessContent.map((val, i) => (
                        <li key={i} data-aos="fade-up" data-aos-delay={val.dataDelay}>
                            <div className={val.className}>{val.num}</div>
                            <h6>{val.heading}</h6>
                            <span>{val.desc}</span>
                        </li>
                    ))}
                </ul>
            </div>
            {/* /.block-style-thirteen */}
        </Fragment>
    )
}

export default FancyFeatureNineteen