import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';

const TwentyFourContent = [
    {
        icon: 'icon_09',
        title: 'Análisis de datos',
        desc: `Examinamos los datos para extraer información clave y enfocados en limpieza, transformación y visualización.`,
        arrow: 'icon_13',
        fadeUp: 'fade-up',
        datadelay: ''
    }, {
        icon: 'icon_12',
        title: 'Ciencia de datos',
        desc: `Capturamos y procesamos la información, ofreciendo perspectivas clave para decisiones estratégicas empresariales.`,
        arrow: 'icon_13',
        fadeUp: 'fade-up',
        datadelay: '100'
    }, {
        icon: 'icon_08',
        title: 'Análisis descriptivo',
        desc: `Encontramos patrones y tendencias en datos mediante estadísticas simples y gráficos claros.`,
        arrow: 'icon_13',
        fadeUp: 'fade-up',
        datadelay: '200'
    }, {
        icon: 'icon_10',
        title: 'Análisis predictivo',
        desc: `Anticipamos comportamientos futuros utilizando modelos avanzados y aprendizaje automático.`,
        arrow: 'icon_13',
        fadeUp: 'fade-up',
        datadelay: '100'
    }, {
        icon: 'icon_11',
        title: 'Desarrollo de software',
        desc: `Creamos soluciones a medida que potencian su negocio con tecnología innovadora y eficiente.`,
        arrow: 'icon_13',
        fadeUp: 'fade-up',
        datadelay: '200'
    }, {
        icon: 'icon_12',
        title: 'Soluciones tailor-made',
        desc: `Diseñamos y desarrollamos herramientas personalizadas para satisfacer sus necesidades específicas.`,
        arrow: 'icon_13',
        fadeUp: 'fade-up',
        datadelay: '300'
    }
]

const FancyFeature24 = () => {
    return (
        <Fragment>
            <div className="row gx-xxl-5">
                {TwentyFourContent.map((val, i) => (
                    <div
                        key={i}
                        className="col-lg-4 col-sm-6 mb-40 xs-mb-30 d-flex"
                        data-aos={val.fade}
                        data-aos-delay={val.datadelay}>
                        <div className="block-style-four">
                            <div className="icon d-flex align-items-end justify-content-center"><img src={`images/icon/${val.icon}.svg`} alt=""/></div>
                         
                                <h5>{val.title}</h5>
                            
                            <p>{val.desc}</p>
              
                        </div>
                        {/* /.block-style-four */}
                    </div>
                ))}
            </div>
        </Fragment>
    )
}

export default FancyFeature24